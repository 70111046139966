import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastButtons = [
    {
      text: 'Dismiss',
      role: 'cancel',
      handler: () => {},
    },
  ];
  constructor(public toastController: ToastController) {}

  async presentToast(
    header: string,
    message: string,
    position: any,
    color: string,
    duration: number,
    icon?: string
  ) {
    if (!icon) {
      switch (color) {
        case 'success':
          icon = 'checkmark-outline';
          break;
        case 'medium':
          icon = 'information-circle-outline';
          break;
        case 'danger':
          icon = 'warning-outline';
          break;
      }
    }

    const toast = await this.toastController.create({
      header,
      message,
      duration,
      position,
      color,
      icon,
      buttons: this.toastButtons,
    });
    await toast.present();
  }
}
