import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { MeApiService } from './api/me.service';
import { UserPushToken } from '../types/me';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private platform: Platform, private meService: MeApiService) {}

  async register() {
    console.log('Registering push notifications...');
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    console.log('Not on web, registering push notifications...');
    await PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        console.log('Push registration rejected!');
      }
    });
  }

  async sendDeviceToken(token: string): Promise<void> {
    const data: UserPushToken = {
      pushToken: token,
    };
    console.log('Sending device token to server...');
    this.meService.updatePushToken(data).subscribe(
      (res) => {
        console.log('Device token sent to server!');
      },
      (err) => {
        console.log('Error sending device token to server!');
      }
    );
  }
}
