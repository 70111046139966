import { ToastService } from './../toast/toast.service';
import { CreateUserDTO, UserDTO, UserPushToken } from './../../types/me';
import { environment } from './../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from 'src/app/types/me';
@Injectable({
  providedIn: 'root',
})
export class MeApiService {
  httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };
  private apiURL = environment.apiURL;
  private rateLimits: {
    [action: string]: { lastActionTime: number; interval: number };
  } = {
    updateMe: { lastActionTime: 0, interval: 1000 },
  };
  constructor(private http: HttpClient, private toastService: ToastService) {}
  getMe(): Observable<User> {
    return this.http
      .get<User>(this.apiURL + '/v1/user/me', this.httpOptions)
      .pipe(
        retry(1),
        catchError((error) => this.handleError(error, false))
      );
  }

  createUser(user: CreateUserDTO): Observable<User> {
    return this.http
      .post<User>(this.apiURL + '/v1/user/me', user, this.httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  updateMe(user: UserDTO): Observable<User> {
    if (!this.canPerformAction('updateMe')) {
      return throwError('Rate limited');
    }
    return this.http
      .patch<User>(this.apiURL + '/v1/user/me', user, this.httpOptions)
      .pipe(
        retry(1),
        catchError((error) => this.handleError(error))
      );
  }

  updatePushToken(user: UserPushToken): Observable<User> {
    return this.http
      .patch<User>(this.apiURL + '/v1/user/me', user, this.httpOptions)
      .pipe(
        retry(1),
        catchError((error) => this.handleError(error))
      );
  }
  // Error handling
  handleError = (error: any, showToast: boolean = true) => {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = error.message;
    }
    errorMessage = errorMessage || error || 'Unknown error';
    console.error(errorMessage);

    if (showToast) {
      this.toastService.presentToast(
        'Error',
        errorMessage ?? 'An unknown error occurred',
        'top',
        'danger',
        2000
      );
    }

    return throwError(errorMessage);
  };

  private canPerformAction(action: string): boolean {
    if (!this.rateLimits[action]) {
      return true; // If action is not rate limited, allow it
    }

    const currentTime = Date.now();
    const { lastActionTime, interval } = this.rateLimits[action];

    if (currentTime - lastActionTime >= interval) {
      this.rateLimits[action].lastActionTime = currentTime;
      return true;
    }
    return false;
  }
}
