import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PublicGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    const isSignedIn = this.authService.getIsLoggedIn();

    // If signed in, redirect to home page
    if (isSignedIn) {
      this.router.navigateByUrl('/home');
    }

    return !isSignedIn;
  }
}
