import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isSignedIn = this.authService.getIsLoggedIn();

    // If not signed in, redirect to welcome page
    if (!isSignedIn) {
      this.router.navigate(['/welcome'], {
        queryParams: { returnUrl: state.url },
      });
    }

    return isSignedIn;
  }
}
