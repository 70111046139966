/* eslint-disable @typescript-eslint/naming-convention */
import { User as Me } from '../../types/me';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { LoadingController } from '@ionic/angular';
import { createClient, SupabaseClient, User } from '@supabase/supabase-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private supabase: SupabaseClient;
  private currentUser = new BehaviorSubject<User | boolean>(false);
  private currentToken = new BehaviorSubject<string | null>(null);
  private returnUrl = '';

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private toastService: ToastService
  ) {
    this.supabase = createClient(
      environment.supabaseUrl,
      environment.supabaseKey,
      {
        auth: {
          detectSessionInUrl: Capacitor.getPlatform() === 'web',
        },
      }
    );

    this.supabase.auth.onAuthStateChange(async (event, sess) => {
      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        console.log('SET USER AND TOKEN');

        this.currentUser.next(sess?.user ?? false);
        this.currentToken.next(sess?.access_token ?? null);
        await this.setPrefrences({
          user: sess?.user ?? null,
          access_token: sess?.access_token ?? '',
          refresh_token: sess?.refresh_token ?? '',
        });

        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
          this.returnUrl = '';
        }
      } else if (event === 'PASSWORD_RECOVERY') {
        console.log('PASSWORD RECOVERY event: ' + event);
        this.router.navigate(['/settings/profile/edit/password']);
      } else {
        console.log('REMOVE USER AND TOKEN event: ' + event);
        this.currentUser.next(false);
        this.currentToken.next(null);
      }
    });
  }

  async loadUser() {
    console.log('LOAD USER');
    const refresh_token = await Preferences.get({ key: 'refresh_token' });

    if (this.currentUser.value && refresh_token) {
      // User is already set, no need to do anything else
      console.log('LOAD USER DONE');
      return;
    }
    await this.refreshToken(refresh_token.value);
    console.log('LOAD USER DONE');
  }

  async deleteAccount() {
    const { data, error } = await this.supabase.rpc('deleteUser');
    if (error) {
      console.log('DELETE ACCOUNT ERROR', error);
      return;
    }
    console.log('DELETE ACCOUNT SUCCESS', data);
    await this.signOut();
  }

  getClient() {
    return this.supabase;
  }

  signIn(credentials: { email: string; password: string }) {
    return this.supabase.auth.signInWithPassword(credentials);
  }

  async refreshToken(refreshToken = this.currentToken.value) {
    if (!refreshToken) {
      return;
    }
    await this.supabase.auth
      .refreshSession({
        refresh_token: refreshToken ?? '',
      })
      .then(async (res) => {
        if (res.error) {
          console.log('REFRESH TOKEN ERROR', res.error);
          this.currentUser.next(false);
          this.currentToken.next(null);
          await this.setPrefrences({
            user: null,
            access_token: '',
            refresh_token: '',
          });
        } else {
          console.log('REFRESH TOKEN SUCCESS', res.data);
          this.currentUser.next(res?.data?.user ?? false);
          this.currentToken.next(res?.data.session?.access_token ?? null);
          await this.setPrefrences({
            user: res?.data?.user ?? null,
            access_token: res?.data.session?.access_token ?? '',
            refresh_token: res?.data.session?.refresh_token ?? '',
          });
        }
      });
  }

  getToken(): Observable<string | null> {
    console.log('GET TOKEN: ' + this.currentToken.value);
    return this.currentToken.asObservable();
  }

  async signOut() {
    await this.setPrefrences({
      user: null,
      access_token: '',
      refresh_token: '',
    });
    await this.setProfile(null);

    await this.supabase.auth.signOut();
    this.router.navigateByUrl('/welcome', { replaceUrl: true });
  }

  getCurrentUser(): Observable<User | boolean> {
    return this.currentUser.asObservable();
  }

  getIsLoggedIn(): boolean {
    return this.getCurrentUserId() !== null;
  }

  getCurrentUserId(): string | null {
    if (this.currentUser.value) {
      return (this.currentUser.value as User).id;
    } else {
      return null;
    }
  }

  signInWithEmail(email: string) {
    return this.supabase.auth.signInWithOtp({ email });
  }

  async signInWithProvider(provider, isMobile = false, returnUrl = null) {
    this.returnUrl = returnUrl;
    await this.supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: isMobile ? 'gift.secret.app://login-callback/' : null,
      },
    });
  }

  async forgotPassword(email: string) {
    await this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: environment.appURL + '/settings/profile/edit/password',
    });
  }

  async changePassword(newPassword: string) {
    await this.supabase.auth.updateUser({ password: newPassword });
  }

  async setToken(access_token: string, refresh_token: string) {
    const spinner = await this.loadingController.create({
      cssClass: 'default-loading',
      message: '<p>Waiting for response</p>',
      spinner: 'crescent',
      backdropDismiss: true,
      keyboardClose: true,
    });
    spinner.present();
    console.log('setToken called');
    console.table({ access_token, refresh_token });
    const res = await this.supabase.auth.setSession({
      access_token,
      refresh_token,
    });
    console.log('setToken response received: ' + res);

    if (res.error) {
      console.error(res.error);
      await spinner.dismiss();
      await this.signOut();
      await this.toastService.presentToast(
        'Error',
        'Invalid token, please sign in again.',
        'top',
        'danger',
        2000
      );
    } else {
      await spinner.dismiss();
      console.log('testing:' + res);
    }
  }

  public async setProfile(profile: Me | null) {
    await Preferences.set({
      key: 'profile',
      value: JSON.stringify(profile ?? '') ?? '',
    });
  }
  /*eslint-disable */
  private async setPrefrences({
    user,
    access_token,
    refresh_token,
  }: {
    user: User | null;
    access_token: string;
    refresh_token: string;
  }) {
    await Preferences.set({
      key: 'user',
      value: JSON.stringify(user ?? '') ?? '',
    });
    await Preferences.set({
      key: 'access_token',
      value: access_token ?? '',
    });
    await Preferences.set({
      key: 'refresh_token',
      value: refresh_token ?? '',
    });
  }
}
