import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { AuthService } from './services/auth/auth.service';
import { PushNotificationService } from './services/push.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function; // Declare the gtag function
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private pushService: PushNotificationService,
    private authService: AuthService,
    private zone: NgZone,
    private router: Router
  ) {
    register();
    this.initializeApp();
  }

  // Initialize app
  initializeApp() {
    // Wait until platform is ready
    this.platform.ready().then(async () => {
      // If we're on a mobile platform (iOS / Android), not web
      if (Capacitor.getPlatform() !== 'web') {
        // Set StatusBar style (dark / light)
        //await StatusBar.setStyle({ style: Style.Dark });
        await StatusBar.hide();

        PushNotifications.addListener('registration', (token: Token) => {
          console.log('Push registration success, token: ' + token.value);
          this.pushService.sendDeviceToken(token.value);
        });

        PushNotifications.addListener('registrationError', (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
          'pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            console.log('Push received: ' + JSON.stringify(notification));
          }
        );

        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            console.log(
              'Push action performed: ' + JSON.stringify(notification)
            );
          }
        );

        // Add deep link route handling
        App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
          this.zone.run(async () => {
            const slug = event.url.split('.gift').pop();
            //get cookies from request

            if (event.url.includes('gift.secret.app://login-callback')) {
              const authToken = new URLSearchParams(
                event.url.split('#')[1]
              ).get('access_token');

              if (authToken) {
                const refreshToken = new URLSearchParams(event.url).get(
                  'refresh_token'
                );
                if (authToken && refreshToken) {
                  await this.authService.setToken(authToken, refreshToken);
                }
              }
            } else if (slug) {
              this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
          });
        });

        // ...
        // do some more config and setup if necessary
        // ...

        // Fake timeout since we do not load any data
        setTimeout(async () => {
          // Hide SplashScreen
          await SplashScreen.hide();
        }, 2000);
      }
    });
  }
}
