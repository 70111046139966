import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PublicGuard } from './guards/public.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome', // TODO: Set this to ''
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/public/welcome/welcome.module').then(
        (m) => m.WelcomePageModule
      ),
    canActivate: [PublicGuard], // Prevent for signed in users
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/secure/secure.module').then((m) => m.SecureModule),
    canActivate: [AuthGuard], // Secure all child pages
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./pages/public/signin/signin.module').then(
        (m) => m.SigninPageModule
      ),
    canActivate: [PublicGuard], // Prevent for signed in users
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/public/signup/signup.module').then(
        (m) => m.SignupPageModule
      ),
    canActivate: [PublicGuard], // Prevent for signed in users
  },
  {
    path: 'password-reset',
    loadChildren: () =>
      import('./pages/public/password-reset/password-reset.module').then(
        (m) => m.PasswordResetPageModule
      ),
    canActivate: [PublicGuard], // Prevent for signed in users
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
